// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tile--lNMvcT_s{width:100%;padding:2rem;overflow:scroll;border-radius:1rem;background-color:var(--tavla-box-background-color);color:var(--tavla-font-color)}", "",{"version":3,"sources":["webpack://./src/components/Tile/Tile.module.scss"],"names":[],"mappings":"AAAA,gBACI,UAAA,CACA,YAAA,CACA,eAAA,CACA,kBAAA,CACA,kDAAA,CACA,6BAAA","sourcesContent":[".Tile {\n    width: 100%;\n    padding: 2rem;\n    overflow: scroll;\n    border-radius: 1rem;\n    background-color: var(--tavla-box-background-color);\n    color: var(--tavla-font-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tile": "Tile--lNMvcT_s"
};
export default ___CSS_LOADER_EXPORT___;
