// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TooltipParagraph--MaKdeI07{color:var(--tavla-box-background-color);margin:0;max-width:14rem;white-space:normal}.Icon--WaEc365S{margin-left:.5rem}", "",{"version":3,"sources":["webpack://./src/tiles/admin/MobileAppTile/MobileAppTile.module.scss"],"names":[],"mappings":"AAAA,4BACI,uCAAA,CACA,QAAA,CACA,eAAA,CACA,kBAAA,CAGJ,gBACI,iBAAA","sourcesContent":[".TooltipParagraph {\n    color: var(--tavla-box-background-color);\n    margin: 0;\n    max-width: 14rem;\n    white-space: normal;\n}\n\n.Icon {\n    margin-left: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TooltipParagraph": "TooltipParagraph--MaKdeI07",
	"Icon": "Icon--WaEc365S"
};
export default ___CSS_LOADER_EXPORT___;
