// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResponsiveTable--I4QO0wrV{width:100%;text-align:left;font-size:2rem;line-height:1.5;border-collapse:collapse}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Responsive/ResponsiveDepartureTile/ResponsiveTable/ResponsiveTable.module.scss"],"names":[],"mappings":"AAAA,2BACI,UAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CACA,wBAAA","sourcesContent":[".ResponsiveTable {\n    width: 100%;\n    text-align: left;\n    font-size: 2rem;\n    line-height: 1.5;\n    border-collapse: collapse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ResponsiveTable": "ResponsiveTable--I4QO0wrV"
};
export default ___CSS_LOADER_EXPORT___;
