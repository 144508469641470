// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dragContainer--QYlEhvTK{height:500px;flex:1}.column--ym02WTdJ{color:#fff;height:500px;background-color:var(--tavla-box-background-color);border:1px solid #fff;padding:1rem;border-radius:.5rem}.dragging--opjaIvHs{transform:scale(1.02);transition:transform ease-in .1s}", "",{"version":3,"sources":["webpack://./src/routes/Admin/LiteSettings/components/ColumnSetting/styles.module.css"],"names":[],"mappings":"AAAA,yBACI,YAAA,CACA,MAAA,CAGJ,kBACI,UAAA,CACA,YAAA,CACA,kDAAA,CACA,qBAAA,CACA,YAAA,CACA,mBAAA,CAGJ,oBACI,qBAAA,CACA,gCAAA","sourcesContent":[".dragContainer {\n    height: 500px;\n    flex: 1;\n}\n\n.column {\n    color: white;\n    height: 500px;\n    background-color: var(--tavla-box-background-color);\n    border: 1px solid white;\n    padding: 1rem;\n    border-radius: 0.5rem;\n}\n\n.dragging {\n    transform: scale(1.02);\n    transition: transform ease-in 0.1s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragContainer": "dragContainer--QYlEhvTK",
	"column": "column--ym02WTdJ",
	"dragging": "dragging--opjaIvHs"
};
export default ___CSS_LOADER_EXPORT___;
