// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Label--ILf3sn6O{color:var(--tavla-label-font-color)}.LogoPreviewImage--SY04Dy05{max-width:100%;display:block;border:.125rem #d1d3d3 dashed}.LogoPreviewToolbar--QmsXd4TU{display:flex;margin-top:.4rem;margin-bottom:1rem;align-items:center}.ToolbarLink--LqOrOnf3{cursor:pointer}.ToolbarLink--LqOrOnf3:first-of-type{margin-right:2rem}.Loader--ZpKnfu9V{max-width:100%}.LogoUpload--P29NDFFq .eds-file-upload__wrapper{width:100%;margin-top:5px;margin-bottom:1rem}.LogoUpload--P29NDFFq .eds-file-upload__dropzone{height:8rem;display:flex;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/routes/Admin/LogoTab/LogoUpload/LogoUpload.module.scss"],"names":[],"mappings":"AAAA,iBACI,mCAAA,CAGJ,4BACI,cAAA,CACA,aAAA,CACA,6BAAA,CAGJ,8BACI,YAAA,CACA,gBAAA,CACA,kBAAA,CACA,kBAAA,CAGJ,uBACI,cAAA,CAEA,qCACI,iBAAA,CAIR,kBACI,cAAA,CAKA,gDACI,UAAA,CACA,cAAA,CACA,kBAAA,CAGJ,iDACI,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".Label {\n    color: var(--tavla-label-font-color);\n}\n\n.LogoPreviewImage {\n    max-width: 100%;\n    display: block;\n    border: 0.125rem #d1d3d3 dashed;\n}\n\n.LogoPreviewToolbar {\n    display: flex;\n    margin-top: 0.4rem;\n    margin-bottom: 1rem;\n    align-items: center;\n}\n\n.ToolbarLink {\n    cursor: pointer;\n\n    &:first-of-type {\n        margin-right: 2rem;\n    }\n}\n\n.Loader {\n    max-width: 100%;\n}\n\n/* :global to overwrite styling from eds */\n.LogoUpload :global {\n    .eds-file-upload__wrapper {\n        width: 100%;\n        margin-top: 5px;\n        margin-bottom: 1rem;\n    }\n\n    .eds-file-upload__dropzone {\n        height: 8rem;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Label": "Label--ILf3sn6O",
	"LogoPreviewImage": "LogoPreviewImage--SY04Dy05",
	"LogoPreviewToolbar": "LogoPreviewToolbar--QmsXd4TU",
	"ToolbarLink": "ToolbarLink--LqOrOnf3",
	"Loader": "Loader--ZpKnfu9V",
	"LogoUpload": "LogoUpload--P29NDFFq"
};
export default ___CSS_LOADER_EXPORT___;
