// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tick--eKdGorbk{margin-top:5px}.LegBone--z9B4leop{margin-right:.125rem}.LegBone--z9B4leop>*{min-width:unset}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Timeline/Tick/Tick.module.scss"],"names":[],"mappings":"AAAA,gBACI,cAAA,CAGJ,mBACI,oBAAA,CAEA,qBACI,eAAA","sourcesContent":[".Tick {\n    margin-top: 5px;\n}\n\n.LegBone {\n    margin-right: 0.125rem;\n\n    > * {\n        min-width: unset;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tick": "Tick--eKdGorbk",
	"LegBone": "LegBone--z9B4leop"
};
export default ___CSS_LOADER_EXPORT___;
