// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RealtimeDetailPanel--i3vIS9SR .eds-expandable-panel__content,.RealtimeDetailPanel--i3vIS9SR .eds-expandable-panel__trigger,.RealtimeDetailPanel--i3vIS9SR .eds-expandable-panel__trigger:hover{background:none;box-shadow:none}.RealtimeDetailPanel--i3vIS9SR .eds-chip{background-color:var(--tavla-border-color)}.RealtimeDetailPanel--i3vIS9SR .eds-filter-chip__icon{background-color:var(--tavla-background-color)}.RouteDisplaySelection--LJZ0T32P{display:flex;align-items:center}.InfoText--xDCcQUwW{margin-right:1rem}", "",{"version":3,"sources":["webpack://./src/tiles/admin/RealtimeTile/components/RealtimeDetailPanel/RealtimeDetailPanel.module.scss"],"names":[],"mappings":"AAEQ,gMAGI,eAAA,CACA,eAAA,CAGJ,yCACI,0CAAA,CAGJ,sDACI,8CAAA,CAKZ,iCACI,YAAA,CACA,kBAAA,CAGJ,oBACI,iBAAA","sourcesContent":[".RealtimeDetailPanel {\n    & :global {\n        .eds-expandable-panel__content,\n        .eds-expandable-panel__trigger,\n        .eds-expandable-panel__trigger:hover {\n            background: none;\n            box-shadow: none;\n        }\n\n        .eds-chip {\n            background-color: var(--tavla-border-color);\n        }\n\n        .eds-filter-chip__icon {\n            background-color: var(--tavla-background-color);\n        }\n    }\n}\n\n.RouteDisplaySelection {\n    display: flex;\n    align-items: center;\n}\n\n.InfoText {\n    margin-right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RealtimeDetailPanel": "RealtimeDetailPanel--i3vIS9SR",
	"RouteDisplaySelection": "RouteDisplaySelection--LJZ0T32P",
	"InfoText": "InfoText--xDCcQUwW"
};
export default ___CSS_LOADER_EXPORT___;
