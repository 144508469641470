// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StopPlaceSearch--N3hxqnjs{width:100%}", "",{"version":3,"sources":["webpack://./src/tiles/admin/StopPlaceTile/components/StopPlaceSearch/StopPlaceSearch.module.scss"],"names":[],"mappings":"AAAA,2BACI,UAAA","sourcesContent":[".StopPlaceSearch {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StopPlaceSearch": "StopPlaceSearch--N3hxqnjs"
};
export default ___CSS_LOADER_EXPORT___;
