// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisplayWrapper--owUMZhCT{width:100%;display:grid;box-sizing:border-box;grid-auto-flow:row;grid-template-columns:repeat(auto-fill, minmax(26rem, 1fr))}.Heading--_Shi4OQA{color:var(--tavla-font-color)}@media(max-width: 50rem){.DisplayWrapper--owUMZhCT{grid-template-columns:1fr}.DisplayCard--f3QSB1rh{margin:0 0 1rem 0}}", "",{"version":3,"sources":["webpack://./src/routes/Admin/DashboardPickerTab/DashboardPickerTab.module.scss"],"names":[],"mappings":"AAEA,0BACI,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,2DAAA,CAGJ,mBACI,6BAAA,CAGJ,yBACI,0BACI,yBAAA,CAGJ,uBACI,iBAAA,CAAA","sourcesContent":["@use 'src/assets/styles/Variables.scss';\n\n.DisplayWrapper {\n    width: 100%;\n    display: grid;\n    box-sizing: border-box;\n    grid-auto-flow: row;\n    grid-template-columns: repeat(auto-fill, minmax(26rem, 1fr));\n}\n\n.Heading {\n    color: var(--tavla-font-color);\n}\n\n@media (max-width: Variables.$breakpoints-medium) {\n    .DisplayWrapper {\n        grid-template-columns: 1fr;\n    }\n\n    .DisplayCard {\n        margin: 0 0 1rem 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DisplayWrapper": "DisplayWrapper--owUMZhCT",
	"Heading": "Heading--_Shi4OQA",
	"DisplayCard": "DisplayCard--f3QSB1rh"
};
export default ___CSS_LOADER_EXPORT___;
