// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardHeader--L65wuJMp{display:flex;flex-direction:row;justify-content:space-between;align-items:end;padding:2rem 0;overflow:hidden}.LogoDescription--fptxcn2P{margin-bottom:0;margin-top:.75rem;font-size:1.25rem;color:var(--tavla-font-color);display:block}.Logo--wyPxwCRO{height:2rem}", "",{"version":3,"sources":["webpack://./src/scenarios/DashboardHeader/DashboardHeader.module.scss"],"names":[],"mappings":"AAAA,2BACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CAGJ,2BACI,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,6BAAA,CACA,aAAA,CAGJ,gBACI,WAAA","sourcesContent":[".DashboardHeader {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: end;\n    padding: 2rem 0;\n    overflow: hidden;\n}\n\n.LogoDescription {\n    margin-bottom: 0;\n    margin-top: 0.75rem;\n    font-size: 1.25rem;\n    color: var(--tavla-font-color);\n    display: block;\n}\n\n.Logo {\n    height: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DashboardHeader": "DashboardHeader--L65wuJMp",
	"LogoDescription": "LogoDescription--fptxcn2P",
	"Logo": "Logo--wyPxwCRO"
};
export default ___CSS_LOADER_EXPORT___;
