// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Exclamation--k6vciTEX{fill:var(--tavla-icon-font-color)}", "",{"version":3,"sources":["webpack://./src/assets/icons/ValidationExclamation/ValidationExclamation.module.scss"],"names":[],"mappings":"AAAA,uBACI,iCAAA","sourcesContent":[".Exclamation {\n    fill: var(--tavla-icon-font-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Exclamation": "Exclamation--k6vciTEX"
};
export default ___CSS_LOADER_EXPORT___;
