// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BusStopSituationIcon--Puc3OCsw{margin-right:1rem}", "",{"version":3,"sources":["webpack://./src/components/SituationInfo/SituationInfo.module.scss"],"names":[],"mappings":"AAEA,gCACI,iBAAA","sourcesContent":["@use 'assets/styles/Variables.scss';\n\n.BusStopSituationIcon {\n    margin-right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BusStopSituationIcon": "BusStopSituationIcon--Puc3OCsw"
};
export default ___CSS_LOADER_EXPORT___;
