// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StopPlacePanel--zAAptyXS{margin:1rem 0 1rem 0}.Header--7V5uyzdv{display:flex;padding:1rem 0;align-items:center;border-bottom:.125rem solid var(--tavla-border-color)}", "",{"version":3,"sources":["webpack://./src/tiles/admin/StopPlaceTile/components/StopPlacePanel/StopPlacePanel.module.scss"],"names":[],"mappings":"AAAA,0BACI,oBAAA,CAGJ,kBACI,YAAA,CACA,cAAA,CACA,kBAAA,CACA,qDAAA","sourcesContent":[".StopPlacePanel {\n    margin: 1rem 0 1rem 0;\n}\n\n.Header {\n    display: flex;\n    padding: 1rem 0;\n    align-items: center;\n    border-bottom: 0.125rem solid var(--tavla-border-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StopPlacePanel": "StopPlacePanel--zAAptyXS",
	"Header": "Header--7V5uyzdv"
};
export default ___CSS_LOADER_EXPORT___;
