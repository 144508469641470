// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomTileModal--_gEiGvji{display:flex;flex-direction:column;gap:1rem;overflow:scroll}.ButtonsContainer--f5zA34Fp{display:flex;justify-content:end;gap:1rem}.ImageText--qP4ZZqQV{min-height:fit-content}", "",{"version":3,"sources":["webpack://./src/tiles/admin/CustomTileModal/CustomTileModal.module.scss"],"names":[],"mappings":"AAAA,2BACI,YAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAGJ,4BACI,YAAA,CACA,mBAAA,CACA,QAAA,CAGJ,qBACI,sBAAA","sourcesContent":[".CustomTileModal {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    overflow: scroll;\n}\n\n.ButtonsContainer {\n    display: flex;\n    justify-content: end;\n    gap: 1rem;\n}\n\n.ImageText {\n    min-height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CustomTileModal": "CustomTileModal--_gEiGvji",
	"ButtonsContainer": "ButtonsContainer--f5zA34Fp",
	"ImageText": "ImageText--qP4ZZqQV"
};
export default ___CSS_LOADER_EXPORT___;
