// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stopPlaceTile--NMyz5A79{height:100%;width:100%;padding:1rem;border-radius:.5rem;background-color:var(--tavla-box-background-color);color:#fff}.lineToggleContainer--D__pwtu7{margin-bottom:.5rem}.columnContainer--lLk_601t{display:flex;gap:10px}.tileHeader--g2_KyYWx{display:flex;justify-content:space-between;align-items:center;padding-bottom:.5rem}", "",{"version":3,"sources":["webpack://./src/routes/Admin/LiteSettings/components/StopPlaceSettings/styles.module.css"],"names":[],"mappings":"AAAA,yBACI,WAAA,CACA,UAAA,CACA,YAAA,CACA,mBAAA,CACA,kDAAA,CACA,UAAA,CAGJ,+BACI,mBAAA,CAGJ,2BACI,YAAA,CACA,QAAA,CAGJ,sBACI,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,oBAAA","sourcesContent":[".stopPlaceTile {\n    height: 100%;\n    width: 100%;\n    padding: 1rem;\n    border-radius: 0.5rem;\n    background-color: var(--tavla-box-background-color);\n    color: white;\n}\n\n.lineToggleContainer {\n    margin-bottom: 0.5rem;\n}\n\n.columnContainer {\n    display: flex;\n    gap: 10px;\n}\n\n.tileHeader {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stopPlaceTile": "stopPlaceTile--NMyz5A79",
	"lineToggleContainer": "lineToggleContainer--D__pwtu7",
	"columnContainer": "columnContainer--lLk_601t",
	"tileHeader": "tileHeader--g2_KyYWx"
};
export default ___CSS_LOADER_EXPORT___;
