// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cross--F1zi_GH1{fill:var(--tavla-icon-font-color)}.Background--UWqxr5b7{fill:var(--tavla-failure-font-color)}", "",{"version":3,"sources":["webpack://./src/assets/icons/ValidationError/ValidationError.module.scss"],"names":[],"mappings":"AAAA,iBACI,iCAAA,CAGJ,sBACI,oCAAA","sourcesContent":[".Cross {\n    fill: var(--tavla-icon-font-color);\n}\n\n.Background {\n    fill: var(--tavla-failure-font-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Cross": "Cross--F1zi_GH1",
	"Background": "Background--UWqxr5b7"
};
export default ___CSS_LOADER_EXPORT___;
