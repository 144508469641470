// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimelineDashboard--HTu2TQ1Z{user-select:none}@media(max-width: 75rem){.TimelineDashboard--HTu2TQ1Z{padding:1rem;overflow:hidden}}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Timeline/TimelineDashboard.module.scss"],"names":[],"mappings":"AAEA,6BACI,gBAAA,CAGJ,yBACI,6BACI,YAAA,CACA,eAAA,CAAA","sourcesContent":["@use 'src/assets/styles/Variables.scss';\n\n.TimelineDashboard {\n    user-select: none;\n}\n\n@media (max-width: Variables.$breakpoints-large) {\n    .TimelineDashboard {\n        padding: 1rem;\n        overflow: hidden;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TimelineDashboard": "TimelineDashboard--HTu2TQ1Z"
};
export default ___CSS_LOADER_EXPORT___;
