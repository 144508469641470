// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Clock--YJGikdlt{text-align:right}.Time--xWnezunL{line-height:2rem;color:var(--tavla-font-color)}.Date--JT7QkDv1{color:var(--tavla-font-color);display:block;font-size:1.25rem;margin-bottom:0;margin-top:.75rem}@media(max-width: 50rem){.Clock--YJGikdlt{display:none}}", "",{"version":3,"sources":["webpack://./src/components/Clock/Clock.module.scss"],"names":[],"mappings":"AAEA,iBACI,gBAAA,CAGJ,gBACI,gBAAA,CACA,6BAAA,CAGJ,gBACI,6BAAA,CACA,aAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CAGJ,yBACI,iBACI,YAAA,CAAA","sourcesContent":["@use 'src/assets/styles/Variables.scss';\n\n.Clock {\n    text-align: right;\n}\n\n.Time {\n    line-height: 2rem;\n    color: var(--tavla-font-color);\n}\n\n.Date {\n    color: var(--tavla-font-color);\n    display: block;\n    font-size: 1.25rem;\n    margin-bottom: 0;\n    margin-top: 0.75rem;\n}\n\n@media (max-width: Variables.$breakpoints-medium) {\n    .Clock {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Clock": "Clock--YJGikdlt",
	"Time": "Time--xWnezunL",
	"Date": "Date--JT7QkDv1"
};
export default ___CSS_LOADER_EXPORT___;
