// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RealtimeTransportModePanel--obJ5vvHS{border-bottom:.125rem solid var(--tavla-border-color)}.Title--QNyWg2Ss{width:100%;display:flex;align-items:center}.Name--MY5hJDhe{margin-right:1rem}.Icon--Txt6GuQo{margin-bottom:-0.1rem;margin-right:.5rem}.Lines--knoVZfyr{width:100%;height:100%;display:flex;flex-wrap:wrap;margin-top:-1rem}.FilterChip--NftZcRh7{margin-right:1rem;margin-top:1rem}.TransportIcon--ojW6SO9F{transform:scale(1.5) translateY(-0.05rem);display:flex;justify-content:center;margin-left:.75rem}", "",{"version":3,"sources":["webpack://./src/tiles/admin/RealtimeTile/components/RealtimeDetailPanel/RealtimeTransportModePanel/RealtimeTransportModePanel.module.scss"],"names":[],"mappings":"AAAA,sCACI,qDAAA,CAGJ,iBACI,UAAA,CACA,YAAA,CACA,kBAAA,CAGJ,gBACI,iBAAA,CAGJ,gBACI,qBAAA,CACA,kBAAA,CAGJ,iBACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CAGJ,sBACI,iBAAA,CACA,eAAA,CAGJ,yBACI,yCAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".RealtimeTransportModePanel {\n    border-bottom: 0.125rem solid var(--tavla-border-color);\n}\n\n.Title {\n    width: 100%;\n    display: flex;\n    align-items: center;\n}\n\n.Name {\n    margin-right: 1rem;\n}\n\n.Icon {\n    margin-bottom: -0.1rem;\n    margin-right: 0.5rem;\n}\n\n.Lines {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: -1rem;\n}\n\n.FilterChip {\n    margin-right: 1rem;\n    margin-top: 1rem;\n}\n\n.TransportIcon {\n    transform: scale(1.5) translateY(-0.05rem);\n    display: flex;\n    justify-content: center;\n    margin-left: 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RealtimeTransportModePanel": "RealtimeTransportModePanel--obJ5vvHS",
	"Title": "Title--QNyWg2Ss",
	"Name": "Name--MY5hJDhe",
	"Icon": "Icon--Txt6GuQo",
	"Lines": "Lines--knoVZfyr",
	"FilterChip": "FilterChip--NftZcRh7",
	"TransportIcon": "TransportIcon--ojW6SO9F"
};
export default ___CSS_LOADER_EXPORT___;
