// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox--DNWdexpT{margin-top:.8rem}.Paragraph--LjmRNzOF{color:var(--tavla-font-color)}.LegendWrapper--iMiBlk8O{margin:1rem 0 1rem 0}", "",{"version":3,"sources":["webpack://./src/tiles/admin/BikeTile/components/BikePanel/BikePanel.module.scss"],"names":[],"mappings":"AAAA,oBACI,gBAAA,CAGJ,qBACI,6BAAA,CAGJ,yBACI,oBAAA","sourcesContent":[".Checkbox {\n    margin-top: 0.8rem;\n}\n\n.Paragraph {\n    color: var(--tavla-font-color);\n}\n\n.LegendWrapper {\n    margin: 1rem 0 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Checkbox": "Checkbox--DNWdexpT",
	"Paragraph": "Paragraph--LjmRNzOF",
	"LegendWrapper": "LegendWrapper--iMiBlk8O"
};
export default ___CSS_LOADER_EXPORT___;
