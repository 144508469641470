// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddButton--R8b8y08a{width:100%;margin-top:1rem;margin-bottom:2rem;background:var(--floating-button-primary-background);color:var(--floating-button-primary-font-color);border:none}.AddButton--R8b8y08a:hover{filter:brightness(0.95)}", "",{"version":3,"sources":["webpack://./src/tiles/admin/CustomTile/components/CustomTilePanel/CustomTilePanel.module.scss"],"names":[],"mappings":"AAAA,qBACI,UAAA,CACA,eAAA,CACA,kBAAA,CACA,oDAAA,CACA,+CAAA,CACA,WAAA,CAEA,2BACI,uBAAA","sourcesContent":[".AddButton {\n    width: 100%;\n    margin-top: 1rem;\n    margin-bottom: 2rem;\n    background: var(--floating-button-primary-background);\n    color: var(--floating-button-primary-font-color);\n    border: none;\n\n    &:hover {\n        filter: brightness(0.95);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddButton": "AddButton--R8b8y08a"
};
export default ___CSS_LOADER_EXPORT___;
