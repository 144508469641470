import React from 'react'

function ScooterIcon(): JSX.Element {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
        >
            <path
                clipRule="evenodd"
                d="M85.5 74.3333C85.3938 74.3333 85.2952 74.3607 85.1898 74.3645L72.5874 30.8407C72.2717 29.7502 71.2732 29 70.138 29H57.1667V33.5333H68.2219C68.4741 33.5333 68.6961 33.7 68.766 33.9421L77.4313 63.8692C77.4842 64.0518 77.442 64.2489 77.3185 64.3938L63.7371 80.367C63.6292 80.4935 63.4715 80.5665 63.3054 80.5665H42.6555C41.6587 76.9802 38.4041 74.3333 34.5 74.3333C29.8056 74.3333 26 78.1389 26 82.8333C26 87.5278 29.8056 91.3333 34.5 91.3333C38.4041 91.3333 41.6587 88.6864 42.6555 85.0998H64.4831C65.2312 85.0998 65.9413 84.7712 66.4258 84.2016L79.0275 69.3823L80.8633 75.722C78.5408 77.2393 77 79.8523 77 82.8333C77 87.5278 80.8056 91.3333 85.5 91.3333C90.1944 91.3333 94 87.5278 94 82.8333C94 78.1389 90.1944 74.3333 85.5 74.3333ZM34.5 86.8001C32.3127 86.8001 30.5333 85.0206 30.5333 82.8333C30.5333 80.6461 32.3127 78.8666 34.5 78.8666C36.6873 78.8666 38.4667 80.6461 38.4667 82.8333C38.4667 85.0206 36.6873 86.8001 34.5 86.8001ZM85.5 86.8001C83.3127 86.8001 81.5333 85.0206 81.5333 82.8333C81.5333 80.6461 83.3127 78.8666 85.5 78.8666C87.6873 78.8666 89.4667 80.6461 89.4667 82.8333C89.4667 85.0206 87.6873 86.8001 85.5 86.8001Z"
                fill="currentColor"
            />
            <circle
                cx="60"
                cy="60"
                r="57.85"
                stroke="currentColor"
                strokeWidth="4.3"
            />
        </svg>
    )
}

export { ScooterIcon }
