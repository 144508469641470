// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navbar--YOAAK2hN{align-items:center;display:flex;justify-content:space-between;padding:1rem 4rem;color:var(--tavla-font-color)}.Logo--ffLpS7fb{height:32px}.List--buM1R4d5{display:flex;flex-direction:row}.Element--mJifj46h{list-style-type:none}.Link--htHHq0_j{display:flex;justify-content:center}.Link--htHHq0_j::after{display:none}.Icon--km6qqeGO{margin-left:.5rem;margin-right:.5rem}@media(max-width: 50rem){.Navbar--YOAAK2hN{padding:.75rem 1rem}.Link--htHHq0_j{padding:0;min-width:unset;margin-left:1rem}.Icon--km6qqeGO{margin:0;font-size:1.5rem}.Text--BNNMolZR{display:none}}", "",{"version":3,"sources":["webpack://./src/scenarios/Navbar/Navbar.module.scss"],"names":[],"mappings":"AAEA,kBACI,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,iBAAA,CACA,6BAAA,CAGJ,gBACI,WAAA,CAGJ,gBACI,YAAA,CACA,kBAAA,CAGJ,mBACI,oBAAA,CAGJ,gBACI,YAAA,CACA,sBAAA,CAEA,uBACI,YAAA,CAIR,gBACI,iBAAA,CACA,kBAAA,CAGJ,yBACI,kBACI,mBAAA,CAGJ,gBACI,SAAA,CACA,eAAA,CACA,gBAAA,CAGJ,gBACI,QAAA,CACA,gBAAA,CAGJ,gBACI,YAAA,CAAA","sourcesContent":["@use 'assets/styles/Variables.scss';\n\n.Navbar {\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    padding: Variables.$space-medium 4rem;\n    color: var(--tavla-font-color);\n}\n\n.Logo {\n    height: 32px;\n}\n\n.List {\n    display: flex;\n    flex-direction: row;\n}\n\n.Element {\n    list-style-type: none;\n}\n\n.Link {\n    display: flex;\n    justify-content: center;\n\n    &::after {\n        display: none;\n    }\n}\n\n.Icon {\n    margin-left: 0.5rem;\n    margin-right: 0.5rem;\n}\n\n@media (max-width: Variables.$breakpoints-medium) {\n    .Navbar {\n        padding: Variables.$space-small Variables.$space-medium;\n    }\n\n    .Link {\n        padding: 0;\n        min-width: unset;\n        margin-left: 1rem;\n    }\n\n    .Icon {\n        margin: 0;\n        font-size: 1.5rem;\n    }\n\n    .Text {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Navbar": "Navbar--YOAAK2hN",
	"Logo": "Logo--ffLpS7fb",
	"List": "List--buM1R4d5",
	"Element": "Element--mJifj46h",
	"Link": "Link--htHHq0_j",
	"Icon": "Icon--km6qqeGO",
	"Text": "Text--BNNMolZR"
};
export default ___CSS_LOADER_EXPORT___;
