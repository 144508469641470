// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScooterClusterMarker--URz7h2CV{z-index:2}", "",{"version":3,"sources":["webpack://./src/scenarios/Map/components/ScooterClusterMarkers/ScooterClusterMarkers.module.scss"],"names":[],"mappings":"AAAA,gCACI,SAAA","sourcesContent":[".ScooterClusterMarker {\n    z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ScooterClusterMarker": "ScooterClusterMarker--URz7h2CV"
};
export default ___CSS_LOADER_EXPORT___;
