// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cancellation--jBXS_NSm{align-items:center;border-radius:.5rem;display:flex;height:.875rem;justify-content:center;margin-left:.375rem;overflow:visible;width:.875rem}.Situation--Ln9YK17H{font-size:1.125rem;height:1.25rem;margin-left:.375rem}.Icon--OBG6G8gm{margin-left:-0.125rem;margin-right:-0.125rem}", "",{"version":3,"sources":["webpack://./src/components/SubLabelIcon/SubLabelIcon.module.scss"],"names":[],"mappings":"AAAA,wBACI,kBAAA,CACA,mBAAA,CACA,YAAA,CACA,cAAA,CACA,sBAAA,CACA,mBAAA,CACA,gBAAA,CACA,aAAA,CAGJ,qBACI,kBAAA,CACA,cAAA,CACA,mBAAA,CAGJ,gBACI,qBAAA,CACA,sBAAA","sourcesContent":[".Cancellation {\n    align-items: center;\n    border-radius: 0.5rem;\n    display: flex;\n    height: 0.875rem;\n    justify-content: center;\n    margin-left: 0.375rem;\n    overflow: visible;\n    width: 0.875rem;\n}\n\n.Situation {\n    font-size: 1.125rem;\n    height: 1.25rem;\n    margin-left: 0.375rem;\n}\n\n.Icon {\n    margin-left: -0.125rem;\n    margin-right: -0.125rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Cancellation": "Cancellation--jBXS_NSm",
	"Situation": "Situation--Ln9YK17H",
	"Icon": "Icon--OBG6G8gm"
};
export default ___CSS_LOADER_EXPORT___;
