// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BusStopTile--PnphZbyM{max-height:80vh;margin-bottom:4rem}.TileHeader--GJTO5YDi{padding-bottom:2rem}.TableHead--sWdwmPNC{border-bottom:none}.Cell--Mb4ztHXm.Cell--Mb4ztHXm.Cell--Mb4ztHXm{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:var(--tavla-font-color)}.Cell--Mb4ztHXm:last-child{width:100%}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/BusStop/components/BusStopTile/BusStopTile.module.scss"],"names":[],"mappings":"AAAA,uBACI,eAAA,CACA,kBAAA,CAGJ,sBACI,mBAAA,CAGJ,qBACI,kBAAA,CAIJ,8CACI,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,6BAAA,CAGJ,2BACI,UAAA","sourcesContent":[".BusStopTile {\n    max-height: 80vh;\n    margin-bottom: 4rem;\n}\n\n.TileHeader {\n    padding-bottom: 2rem;\n}\n\n.TableHead {\n    border-bottom: none;\n}\n\n// Triple selector to overwrite Entur component\n.Cell.Cell.Cell {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    color: var(--tavla-font-color);\n}\n\n.Cell:last-child {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BusStopTile": "BusStopTile--PnphZbyM",
	"TileHeader": "TileHeader--GJTO5YDi",
	"TableHead": "TableHead--sWdwmPNC",
	"Cell": "Cell--Mb4ztHXm"
};
export default ___CSS_LOADER_EXPORT___;
