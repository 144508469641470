// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DetailsInView--K5AGC70B{margin-top:0}", "",{"version":3,"sources":["webpack://./src/tiles/admin/StopPlaceTile/StopPlaceTile.module.scss"],"names":[],"mappings":"AAAA,yBACI,YAAA","sourcesContent":[".DetailsInView {\n    margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DetailsInView": "DetailsInView--K5AGC70B"
};
export default ___CSS_LOADER_EXPORT___;
