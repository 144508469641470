// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ThemeBackground--fSe71xtv{background-color:var(--tavla-background-color);min-height:100vh}", "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA,2BACI,8CAAA,CACA,gBAAA","sourcesContent":[".ThemeBackground {\n    background-color: var(--tavla-background-color);\n    min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ThemeBackground": "ThemeBackground--fSe71xtv"
};
export default ___CSS_LOADER_EXPORT___;
