// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Center--IrvR6Yqo{align-items:center;display:flex;justify-content:space-between;width:3.5rem}.Url--fs65ErvC{display:flex;flex-direction:row}", "",{"version":3,"sources":["webpack://./src/routes/MyBoards/OwnedBoards/ListView/ListView.module.scss"],"names":[],"mappings":"AAAA,kBACI,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,YAAA,CAGJ,eACI,YAAA,CACA,kBAAA","sourcesContent":[".Center {\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    width: 3.5rem;\n}\n\n.Url {\n    display: flex;\n    flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Center": "Center--IrvR6Yqo",
	"Url": "Url--fs65ErvC"
};
export default ___CSS_LOADER_EXPORT___;
