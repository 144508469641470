// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageTile--uzparth2{width:100%;height:100%;margin-bottom:1rem;border-radius:1rem;background-size:cover;background-position:center;background-color:var(--tavla-box-background-color);display:flex;flex-direction:column;align-items:center;justify-content:end}@media(max-width: 50rem){.ImageTile--uzparth2{min-height:20rem}}.InfoBox--zdmetk5C{max-width:90%;width:fit-content;border-radius:.5rem;background-color:var(--tavla-box-background-color);padding:0 1rem;margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./src/tiles/dashboard/ImageTile/ImageTile.module.scss"],"names":[],"mappings":"AAEA,qBACI,UAAA,CACA,WAAA,CACA,kBAAA,CACA,kBAAA,CACA,qBAAA,CACA,0BAAA,CACA,kDAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,mBAAA,CAEA,yBAbJ,qBAcQ,gBAAA,CAAA,CAIR,mBACI,aAAA,CACA,iBAAA,CACA,mBAAA,CACA,kDAAA,CACA,cAAA,CACA,kBAAA","sourcesContent":["@use 'src/assets/styles/Variables.scss';\n\n.ImageTile {\n    width: 100%;\n    height: 100%;\n    margin-bottom: 1rem;\n    border-radius: 1rem;\n    background-size: cover;\n    background-position: center;\n    background-color: var(--tavla-box-background-color);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: end;\n\n    @media (max-width: Variables.$breakpoints-medium) {\n        min-height: 20rem;\n    }\n}\n\n.InfoBox {\n    max-width: 90%;\n    width: fit-content;\n    border-radius: 0.5rem;\n    background-color: var(--tavla-box-background-color);\n    padding: 0 1rem;\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImageTile": "ImageTile--uzparth2",
	"InfoBox": "InfoBox--zdmetk5C"
};
export default ___CSS_LOADER_EXPORT___;
