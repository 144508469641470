// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalkTrip--WTlM_uJO{font-style:normal;font-weight:normal;font-size:1rem;color:var(--tavla-font-color)}", "",{"version":3,"sources":["webpack://./src/components/WalkTrip/WalkTrip.module.scss"],"names":[],"mappings":"AAAA,oBACI,iBAAA,CACA,kBAAA,CACA,cAAA,CACA,6BAAA","sourcesContent":[".WalkTrip {\n    font-style: normal;\n    font-weight: normal;\n    font-size: 1rem;\n    color: var(--tavla-font-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WalkTrip": "WalkTrip--WTlM_uJO"
};
export default ___CSS_LOADER_EXPORT___;
