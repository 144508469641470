// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuButton--YY8Ex9cy{color:var(--tavla-font-color);background-color:rgba(0,0,0,0);padding:2rem;border:hidden;font:inherit;transition-duration:.3s;transition-property:transform;transition-timing-function:ease-out}.MenuButton--YY8Ex9cy:hover{cursor:pointer;transform:translateY(-8px);transition:transform}", "",{"version":3,"sources":["webpack://./src/scenarios/DashboardWrapper/components/MenuButton/MenuButton.module.scss"],"names":[],"mappings":"AAAA,sBACI,6BAAA,CACA,8BAAA,CACA,YAAA,CACA,aAAA,CACA,YAAA,CACA,uBAAA,CACA,6BAAA,CACA,mCAAA,CAGJ,4BACI,cAAA,CACA,0BAAA,CACA,oBAAA","sourcesContent":[".MenuButton {\n    color: var(--tavla-font-color);\n    background-color: transparent;\n    padding: 2rem;\n    border: hidden;\n    font: inherit;\n    transition-duration: 0.3s;\n    transition-property: transform;\n    transition-timing-function: ease-out;\n}\n\n.MenuButton:hover {\n    cursor: pointer;\n    transform: translateY(-8px);\n    transition: transform;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuButton": "MenuButton--YY8Ex9cy"
};
export default ___CSS_LOADER_EXPORT___;
