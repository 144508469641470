// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ThemeTab--sbCYUyBL{color:var(--tavla-font-color);width:100%}.Paragraph--AuOWlN19{color:var(--tavla-font-color);max-width:40rem}.Heading--A95RT1GL{color:var(--tavla-font-color)}.Grid--W_cc9BDS{display:grid;grid-template-columns:repeat(auto-fill, minmax(22rem, 1fr))}.ThemeCard--LhxFMHu6{max-width:35rem}.FontSizeButtons--FEeWMmR1{display:flex;flex-direction:row;align-items:center;justify-content:flex-start}.FontSizeButton--UAwHf625{width:11rem;min-width:8rem}.FontSizePercentage--F_jdo8OU{width:5rem;text-align:center}", "",{"version":3,"sources":["webpack://./src/routes/Admin/ThemeTab/ThemeTab.module.scss"],"names":[],"mappings":"AAAA,oBACI,6BAAA,CACA,UAAA,CAGJ,qBACI,6BAAA,CACA,eAAA,CAGJ,mBACI,6BAAA,CAGJ,gBACI,YAAA,CACA,2DAAA,CAGJ,qBACI,eAAA,CAGJ,2BACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,0BAAA,CAGJ,0BACI,WAAA,CACA,cAAA,CAGJ,8BACI,UAAA,CACA,iBAAA","sourcesContent":[".ThemeTab {\n    color: var(--tavla-font-color);\n    width: 100%;\n}\n\n.Paragraph {\n    color: var(--tavla-font-color);\n    max-width: 40rem;\n}\n\n.Heading {\n    color: var(--tavla-font-color);\n}\n\n.Grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));\n}\n\n.ThemeCard {\n    max-width: 35rem;\n}\n\n.FontSizeButtons {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n}\n\n.FontSizeButton {\n    width: 11rem;\n    min-width: 8rem;\n}\n\n.FontSizePercentage {\n    width: 5rem;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ThemeTab": "ThemeTab--sbCYUyBL",
	"Paragraph": "Paragraph--AuOWlN19",
	"Heading": "Heading--A95RT1GL",
	"Grid": "Grid--W_cc9BDS",
	"ThemeCard": "ThemeCard--LhxFMHu6",
	"FontSizeButtons": "FontSizeButtons--FEeWMmR1",
	"FontSizeButton": "FontSizeButton--UAwHf625",
	"FontSizePercentage": "FontSizePercentage--F_jdo8OU"
};
export default ___CSS_LOADER_EXPORT___;
