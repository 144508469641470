// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminTile--LdxB8P86{margin-bottom:3rem;padding:2rem;background-color:var(--tavla-box-background-color);border-radius:1rem;flex-grow:1;flex-basis:0}.Header--ryoO8c32{display:flex;justify-content:space-between;margin:1rem 0 1rem 0}.Heading--ZPPyr1n2{margin:0;max-width:75%;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.Switch--HxopX7no{padding:0}", "",{"version":3,"sources":["webpack://./src/components/AdminTile/AdminTile.module.scss"],"names":[],"mappings":"AAAA,qBACI,kBAAA,CACA,YAAA,CACA,kDAAA,CACA,kBAAA,CACA,WAAA,CACA,YAAA,CAGJ,kBACI,YAAA,CACA,6BAAA,CACA,oBAAA,CAGJ,mBACI,QAAA,CACA,aAAA,CACA,sBAAA,CACA,eAAA,CACA,kBAAA,CAGJ,kBACI,SAAA","sourcesContent":[".AdminTile {\n    margin-bottom: 3rem;\n    padding: 2rem;\n    background-color: var(--tavla-box-background-color);\n    border-radius: 1rem;\n    flex-grow: 1;\n    flex-basis: 0;\n}\n\n.Header {\n    display: flex;\n    justify-content: space-between;\n    margin: 1rem 0 1rem 0;\n}\n\n.Heading {\n    margin: 0;\n    max-width: 75%;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n}\n\n.Switch {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdminTile": "AdminTile--LdxB8P86",
	"Header": "Header--ryoO8c32",
	"Heading": "Heading--ZPPyr1n2",
	"Switch": "Switch--HxopX7no"
};
export default ___CSS_LOADER_EXPORT___;
