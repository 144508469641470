// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorWrapper--sk4PebLH{display:flex;flex-direction:column;align-items:center;width:100%;padding:2rem 5rem 0 5rem;color:var(--tavla-font-color)}.StyleImage--hVHUIqxk{padding-bottom:2rem;width:25rem;max-width:100%;min-width:15rem}.Heading--BZHPTqb0{color:var(--tavla-font-color)}.MainText--FIfxkSNX{max-width:27rem;text-align:center}.PrimaryButton--i4PzuKc9{margin:3rem;background-color:var(--tavla-button-background-color);color:var(--tavla-font-color)}.PrimaryButton--i4PzuKc9:hover{background-color:var(--tavla-hover-color)}", "",{"version":3,"sources":["webpack://./src/scenarios/ErrorPages/components/ErrorWrapper/ErrorWrapper.module.scss"],"names":[],"mappings":"AAAA,wBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,wBAAA,CACA,6BAAA,CAGJ,sBACI,mBAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CAGJ,mBACI,6BAAA,CAGJ,oBACI,eAAA,CACA,iBAAA,CAGJ,yBACI,WAAA,CACA,qDAAA,CACA,6BAAA,CAEA,+BACI,yCAAA","sourcesContent":[".ErrorWrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    padding: 2rem 5rem 0 5rem;\n    color: var(--tavla-font-color);\n}\n\n.StyleImage {\n    padding-bottom: 2rem;\n    width: 25rem;\n    max-width: 100%;\n    min-width: 15rem;\n}\n\n.Heading {\n    color: var(--tavla-font-color);\n}\n\n.MainText {\n    max-width: 27rem;\n    text-align: center;\n}\n\n.PrimaryButton {\n    margin: 3rem;\n    background-color: var(--tavla-button-background-color);\n    color: var(--tavla-font-color);\n\n    &:hover {\n        background-color: var(--tavla-hover-color);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ErrorWrapper": "ErrorWrapper--sk4PebLH",
	"StyleImage": "StyleImage--hVHUIqxk",
	"Heading": "Heading--BZHPTqb0",
	"MainText": "MainText--FIfxkSNX",
	"PrimaryButton": "PrimaryButton--i4PzuKc9"
};
export default ___CSS_LOADER_EXPORT___;
