// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomMenu--ny3vDVSp{padding:0 2rem;min-height:6.4rem;width:fit-content;background-color:var(--tavla-button-background-color);border-radius:10px 10px 0 0;z-index:5;position:fixed;margin-left:auto;margin-right:auto;bottom:0;left:0;right:0;display:flex;visibility:hidden;transform:translate(0, 100%);transition:transform .3s ease-in,visibility .3s}.HiddenMenu--kVW6ZIpO,.BottomMenu--ny3vDVSp:hover,.BottomMenu--ny3vDVSp:focus-within{visibility:visible;transform:translate(0, 0)}@media(max-width: 50rem){.BottomMenu--ny3vDVSp{justify-content:space-evenly;width:100%;border-radius:0}}", "",{"version":3,"sources":["webpack://./src/scenarios/DashboardWrapper/components/BottomMenu/BottomMenu.module.scss"],"names":[],"mappings":"AAEA,sBACI,cAAA,CACA,iBAAA,CACA,iBAAA,CACA,qDAAA,CACA,2BAAA,CACA,SAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,YAAA,CACA,iBAAA,CACA,4BAAA,CACA,+CAAA,CAGJ,qFAGI,kBAAA,CACA,yBAAA,CAGJ,yBACI,sBACI,4BAAA,CACA,UAAA,CACA,eAAA,CAAA","sourcesContent":["@use 'src/assets/styles/Variables.scss';\n\n.BottomMenu {\n    padding: 0 2rem;\n    min-height: 6.4rem;\n    width: fit-content;\n    background-color: var(--tavla-button-background-color);\n    border-radius: 10px 10px 0 0;\n    z-index: 5;\n    position: fixed;\n    margin-left: auto;\n    margin-right: auto;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    display: flex;\n    visibility: hidden;\n    transform: translate(0, 100%);\n    transition: transform 0.3s ease-in, visibility 0.3s;\n}\n\n.HiddenMenu,\n.BottomMenu:hover,\n.BottomMenu:focus-within {\n    visibility: visible;\n    transform: translate(0, 0);\n}\n\n@media (max-width: Variables.$breakpoints-medium) {\n    .BottomMenu {\n        justify-content: space-evenly;\n        width: 100%;\n        border-radius: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BottomMenu": "BottomMenu--ny3vDVSp",
	"HiddenMenu": "HiddenMenu--kVW6ZIpO"
};
export default ___CSS_LOADER_EXPORT___;
