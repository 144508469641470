// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".column--K4onRW0J{background-color:var(--tavla-box-background-color);border:1px solid #fff;padding:1rem;border-radius:.5rem}", "",{"version":3,"sources":["webpack://./src/routes/Admin/LiteSettings/components/AddColumnSettings/styles.module.css"],"names":[],"mappings":"AAAA,kBACI,kDAAA,CACA,qBAAA,CACA,YAAA,CACA,mBAAA","sourcesContent":[".column {\n    background-color: var(--tavla-box-background-color);\n    border: 1px solid white;\n    padding: 1rem;\n    border-radius: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": "column--K4onRW0J"
};
export default ___CSS_LOADER_EXPORT___;
