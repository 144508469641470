// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterChip--dMd0sCGf{background-color:var(--tavla-border-color);margin-top:.5rem}", "",{"version":3,"sources":["webpack://./src/tiles/admin/WeatherTile/components/WeatherPanel/WeatherPanel.module.scss"],"names":[],"mappings":"AAAA,sBACI,0CAAA,CACA,gBAAA","sourcesContent":[".FilterChip {\n    background-color: var(--tavla-border-color);\n    margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FilterChip": "FilterChip--dMd0sCGf"
};
export default ___CSS_LOADER_EXPORT___;
