// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Border--XJleShcF{border-top:#fff solid 1px;min-width:100px;margin-bottom:0;background-color:#181c56}.HomeFooter--URFsguJs{padding:4rem 4rem 2rem;margin:0 auto;max-width:80rem}.FlexContainer--BpT7ynpR{display:flex;flex-direction:row;justify-content:space-between}.FlexItem--y2zS5cgB{margin-right:1rem}.FooterInfoText--hpbrSbqe{font-size:.75rem;margin:1rem 0}.Icon--x7vpexJm{margin-left:.5em}.RoundIcon--liD8e7Se{border-radius:50%;display:flex;background-color:#fff;margin-right:.75rem;padding:.5rem}.IconWrapper--O9Wpzule{margin-top:2rem;display:flex}", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA,kBACI,yBAAA,CACA,eAAA,CACA,eAAA,CACA,wBAAA,CAGJ,sBACI,sBAAA,CACA,aAAA,CACA,eAAA,CAGJ,yBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CAGJ,oBACI,iBAAA,CAGJ,0BACI,gBAAA,CACA,aAAA,CAGJ,gBACI,gBAAA,CAGJ,qBACI,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,aAAA,CAGJ,uBACI,eAAA,CACA,YAAA","sourcesContent":[".Border {\n    border-top: white solid 1px;\n    min-width: 100px;\n    margin-bottom: 0;\n    background-color: #181c56;\n}\n\n.HomeFooter {\n    padding: 4rem 4rem 2rem;\n    margin: 0 auto;\n    max-width: 80rem;\n}\n\n.FlexContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.FlexItem {\n    margin-right: 1rem;\n}\n\n.FooterInfoText {\n    font-size: 0.75rem;\n    margin: 1rem 0;\n}\n\n.Icon {\n    margin-left: 0.5em;\n}\n\n.RoundIcon {\n    border-radius: 50%;\n    display: flex;\n    background-color: white;\n    margin-right: 0.75rem;\n    padding: 0.5rem;\n}\n\n.IconWrapper {\n    margin-top: 2rem;\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Border": "Border--XJleShcF",
	"HomeFooter": "HomeFooter--URFsguJs",
	"FlexContainer": "FlexContainer--BpT7ynpR",
	"FlexItem": "FlexItem--y2zS5cgB",
	"FooterInfoText": "FooterInfoText--hpbrSbqe",
	"Icon": "Icon--x7vpexJm",
	"RoundIcon": "RoundIcon--liD8e7Se",
	"IconWrapper": "IconWrapper--O9Wpzule"
};
export default ___CSS_LOADER_EXPORT___;
