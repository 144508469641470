// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChronoDepartureTile--IjVf6nar{height:100%}.TableHead--Q4XOn7NC{border-bottom:none}.Cell--ax_bi1O9.Cell--ax_bi1O9.Cell--ax_bi1O9{padding:1rem 0 1rem .1rem;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:var(--tavla-font-color)}.Icon--wHPmNQ0C{width:14%}.Line--hH7GTqIC{width:auto}.Departure--PjY7LVbd{width:20%}.Track--n0lKRVOq{width:11%}.Situation--NwxY1TDB{width:11%}@media(min-width: 50rem){.Track--n0lKRVOq{width:10%}.Situation--NwxY1TDB{width:10%}}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Chrono/ChronoDepartureTile/ChronoDepartureTile.module.scss"],"names":[],"mappings":"AAEA,+BACI,WAAA,CAGJ,qBACI,kBAAA,CAIJ,8CACI,yBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,6BAAA,CAGJ,gBACI,SAAA,CAGJ,gBACI,UAAA,CAGJ,qBACI,SAAA,CAGJ,iBACI,SAAA,CAGJ,qBACI,SAAA,CAGJ,yBACI,iBACI,SAAA,CAGJ,qBACI,SAAA,CAAA","sourcesContent":["@use 'src/assets/styles/Variables.scss';\n\n.ChronoDepartureTile {\n    height: 100%;\n}\n\n.TableHead {\n    border-bottom: none;\n}\n\n// Triple selector to overwrite Entur design system styling\n.Cell.Cell.Cell {\n    padding: 1rem 0 1rem 0.1rem;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    color: var(--tavla-font-color);\n}\n\n.Icon {\n    width: 14%;\n}\n\n.Line {\n    width: auto;\n}\n\n.Departure {\n    width: 20%;\n}\n\n.Track {\n    width: 11%;\n}\n\n.Situation {\n    width: 11%;\n}\n\n@media (min-width: Variables.$breakpoints-medium) {\n    .Track {\n        width: 10%;\n    }\n\n    .Situation {\n        width: 10%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChronoDepartureTile": "ChronoDepartureTile--IjVf6nar",
	"TableHead": "TableHead--Q4XOn7NC",
	"Cell": "Cell--ax_bi1O9",
	"Icon": "Icon--wHPmNQ0C",
	"Line": "Line--hH7GTqIC",
	"Departure": "Departure--PjY7LVbd",
	"Track": "Track--n0lKRVOq",
	"Situation": "Situation--NwxY1TDB"
};
export default ___CSS_LOADER_EXPORT___;
