// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Poster--rThwIF3E{display:flex;flex-direction:column;height:1920px;color:#fff}.Header--f04bFX6L{display:flex;justify-content:flex-end;padding:5rem 5rem 0 0}.HeaderLogo--v652wZ_2{height:3.5rem}.Content--kwuxEmuf{height:100%;padding:0 6rem 0 6rem}.Heading--Z7LH1zsh{font-size:7rem;font-weight:600}.HeadingWrapper--WgcPvwh8{padding:8rem 0 4rem 0}.MobilityTiles--nJ0cxtx8{display:flex;flex-direction:row;justify-content:space-between}.MobilityTilesList--TOK7bwBs{flex-direction:column;position:relative;top:8%}.Rotated--NoOLWprS{transform:rotate(-90deg) translate(-100vh);transform-origin:top left;width:100vh;height:100vh}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Poster/Poster.module.scss"],"names":[],"mappings":"AAAA,kBACI,YAAA,CACA,qBAAA,CACA,aAAA,CACA,UAAA,CAGJ,kBACI,YAAA,CACA,wBAAA,CACA,qBAAA,CAGJ,sBACI,aAAA,CAGJ,mBACI,WAAA,CACA,qBAAA,CAGJ,mBACI,cAAA,CACA,eAAA,CAGJ,0BACI,qBAAA,CAGJ,yBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CAGJ,6BACI,qBAAA,CACA,iBAAA,CACA,MAAA,CAGJ,mBACI,0CAAA,CACA,yBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".Poster {\n    display: flex;\n    flex-direction: column;\n    height: 1920px;\n    color: white;\n}\n\n.Header {\n    display: flex;\n    justify-content: flex-end;\n    padding: 5rem 5rem 0 0;\n}\n\n.HeaderLogo {\n    height: 3.5rem;\n}\n\n.Content {\n    height: 100%;\n    padding: 0 6rem 0 6rem;\n}\n\n.Heading {\n    font-size: 7rem;\n    font-weight: 600;\n}\n\n.HeadingWrapper {\n    padding: 8rem 0 4rem 0;\n}\n\n.MobilityTiles {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.MobilityTilesList {\n    flex-direction: column;\n    position: relative;\n    top: 8%;\n}\n\n.Rotated {\n    transform: rotate(-90deg) translate(-100vh);\n    transform-origin: top left;\n    width: 100vh;\n    height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Poster": "Poster--rThwIF3E",
	"Header": "Header--f04bFX6L",
	"HeaderLogo": "HeaderLogo--v652wZ_2",
	"Content": "Content--kwuxEmuf",
	"Heading": "Heading--Z7LH1zsh",
	"HeadingWrapper": "HeadingWrapper--WgcPvwh8",
	"MobilityTiles": "MobilityTiles--nJ0cxtx8",
	"MobilityTilesList": "MobilityTilesList--TOK7bwBs",
	"Rotated": "Rotated--NoOLWprS"
};
export default ___CSS_LOADER_EXPORT___;
