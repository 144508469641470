// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Route--IE3vnkzi{display:flex;flex-direction:row;align-items:center;margin-bottom:.5rem}", "",{"version":3,"sources":["webpack://./src/tiles/admin/StopPlaceTile/components/StopPlacePanel/PanelRow/RouteCheckbox/RouteCheckbox.module.scss"],"names":[],"mappings":"AAAA,iBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,mBAAA","sourcesContent":[".Route {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Route": "Route--IE3vnkzi"
};
export default ___CSS_LOADER_EXPORT___;
