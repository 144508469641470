// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Heading--x5P7aYuG{margin-top:0;line-height:1;margin-bottom:1.5rem}.Paragraph--J1jWblUG{color:var(--tavla-font-color)}.InputGroup--hi7MT3fa{display:flex;align-items:flex-start;gap:1rem}.TextFieldWrapper--Spi7mG1W{width:100%}", "",{"version":3,"sources":["webpack://./src/routes/Admin/LogoTab/Description/Description.module.scss"],"names":[],"mappings":"AAAA,mBACI,YAAA,CACA,aAAA,CACA,oBAAA,CAGJ,qBACI,6BAAA,CAGJ,sBACI,YAAA,CACA,sBAAA,CACA,QAAA,CAGJ,4BACI,UAAA","sourcesContent":[".Heading {\n    margin-top: 0;\n    line-height: 1;\n    margin-bottom: 1.5rem;\n}\n\n.Paragraph {\n    color: var(--tavla-font-color);\n}\n\n.InputGroup {\n    display: flex;\n    align-items: flex-start;\n    gap: 1rem;\n}\n\n.TextFieldWrapper {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Heading": "Heading--x5P7aYuG",
	"Paragraph": "Paragraph--J1jWblUG",
	"InputGroup": "InputGroup--hi7MT3fa",
	"TextFieldWrapper": "TextFieldWrapper--Spi7mG1W"
};
export default ___CSS_LOADER_EXPORT___;
