// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LongNumber--bEkA_pmA{font-size:6rem}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Poster/components/NumberDisplay/NumberDisplay.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA","sourcesContent":[".LongNumber {\n    font-size: 6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LongNumber": "LongNumber--bEkA_pmA"
};
export default ___CSS_LOADER_EXPORT___;
