// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MapTile--QsO99MvC{height:inherit;width:inherit;background-color:var(--tavla-box-background-color);color:var(--tavla-font-color);flex:none;overflow:auto;overflow-x:hidden;padding-top:0}@media(max-width: 50rem){.MapTile--QsO99MvC{height:39.375rem}}", "",{"version":3,"sources":["webpack://./src/tiles/dashboard/MapTile/MapTile.module.scss"],"names":[],"mappings":"AAEA,mBACI,cAAA,CACA,aAAA,CACA,kDAAA,CACA,6BAAA,CACA,SAAA,CACA,aAAA,CACA,iBAAA,CACA,aAAA,CAEA,yBAVJ,mBAWQ,gBAAA,CAAA","sourcesContent":["@use 'src/assets/styles/Variables.scss';\n\n.MapTile {\n    height: inherit;\n    width: inherit;\n    background-color: var(--tavla-box-background-color);\n    color: var(--tavla-font-color);\n    flex: none;\n    overflow: auto;\n    overflow-x: hidden;\n    padding-top: 0;\n\n    @media (max-width: Variables.$breakpoints-medium) {\n        height: 39.375rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapTile": "MapTile--QsO99MvC"
};
export default ___CSS_LOADER_EXPORT___;
