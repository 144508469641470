// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Responsive--jX5oGVq8{display:grid;grid-template-columns:repeat(auto-fit, minmax(37.5rem, 1fr));min-height:0;gap:1rem;flex:1 0 0}.ResponsiveWrapper--rDf_ckSY{height:100vh;display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Responsive/ResponsiveDashboard.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,4DAAA,CACA,YAAA,CACA,QAAA,CACA,UAAA,CAGJ,6BACI,YAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".Responsive {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(37.5rem, 1fr));\n    min-height: 0;\n    gap: 1rem;\n    flex: 1 0 0;\n}\n\n.ResponsiveWrapper {\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Responsive": "Responsive--jX5oGVq8",
	"ResponsiveWrapper": "ResponsiveWrapper--rDf_ckSY"
};
export default ___CSS_LOADER_EXPORT___;
