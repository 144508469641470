// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CompactDepartureTile--YSEI9mWt{height:100%}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Compact/CompactDepartureTile/CompactDepartureTile.module.scss"],"names":[],"mappings":"AAAA,gCACI,WAAA","sourcesContent":[".CompactDepartureTile {\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompactDepartureTile": "CompactDepartureTile--YSEI9mWt"
};
export default ___CSS_LOADER_EXPORT___;
