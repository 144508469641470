// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WeatherIcon--Md_z2HZv{position:relative;font-size:5.5rem}.WeatherIcon--Md_z2HZv:active{pointer-events:none}.Sun--KvLTwAYg{animation:spin--If8qJYvE 30s linear infinite}@keyframes spin--If8qJYvE{100%{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/tiles/dashboard/WeatherTile/WeatherIcon/WeatherIcon.module.scss"],"names":[],"mappings":"AAAA,uBACI,iBAAA,CACA,gBAAA,CAEA,8BACI,mBAAA,CAIR,eACI,4CAAA,CAGJ,0BACI,KACI,wBAAA,CAAA","sourcesContent":[".WeatherIcon {\n    position: relative;\n    font-size: 5.5rem;\n\n    &:active {\n        pointer-events: none;\n    }\n}\n\n.Sun {\n    animation: spin 30s linear infinite;\n}\n\n@keyframes spin {\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WeatherIcon": "WeatherIcon--Md_z2HZv",
	"Sun": "Sun--KvLTwAYg",
	"spin": "spin--If8qJYvE"
};
export default ___CSS_LOADER_EXPORT___;
