// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResponsiveTile--qNalm9yQ{height:100%;overflow:hidden}.Heading--yI4cJQr1{font-weight:600;font-size:2.5rem;margin:1rem 0}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Responsive/ResponsiveDepartureTile/ResponsiveDepartureTile.module.scss"],"names":[],"mappings":"AAAA,0BACI,WAAA,CACA,eAAA,CAGJ,mBACI,eAAA,CACA,gBAAA,CACA,aAAA","sourcesContent":[".ResponsiveTile {\n    height: 100%;\n    overflow: hidden;\n}\n\n.Heading {\n    font-weight: 600;\n    font-size: 2.5rem;\n    margin: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ResponsiveTile": "ResponsiveTile--qNalm9yQ",
	"Heading": "Heading--yI4cJQr1"
};
export default ___CSS_LOADER_EXPORT___;
