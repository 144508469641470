// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GridView--L_w2_guh{display:grid;grid-template-columns:repeat(auto-fill, minmax(25rem, 1fr));gap:2rem}.TabPanel--JGyWlGRG{padding:0 2rem}.AddBoard--Q3bfKvEH{border:2px solid var(--tavla-border-color);border-radius:.25rem;padding:1rem}.AddBoard--Q3bfKvEH:hover{background-color:var(--tavla-selected-color);border-color:var(--tavla-input-border-hover-color);cursor:pointer}.AddIcon--AIYoEjjg{width:100%;height:auto;color:var(--tavla-font-color)}", "",{"version":3,"sources":["webpack://./src/routes/MyBoards/OwnedBoards/GridView/GridView.module.scss"],"names":[],"mappings":"AAAA,oBACI,YAAA,CACA,2DAAA,CACA,QAAA,CAGJ,oBACI,cAAA,CAGJ,oBACI,0CAAA,CACA,oBAAA,CACA,YAAA,CAEA,0BACI,4CAAA,CACA,kDAAA,CACA,cAAA,CAIR,mBACI,UAAA,CACA,WAAA,CACA,6BAAA","sourcesContent":[".GridView {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));\n    gap: 2rem;\n}\n\n.TabPanel {\n    padding: 0 2rem;\n}\n\n.AddBoard {\n    border: 2px solid var(--tavla-border-color);\n    border-radius: 0.25rem;\n    padding: 1rem;\n\n    &:hover {\n        background-color: var(--tavla-selected-color);\n        border-color: var(--tavla-input-border-hover-color);\n        cursor: pointer;\n    }\n}\n\n.AddIcon {\n    width: 100%;\n    height: auto;\n    color: var(--tavla-font-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GridView": "GridView--L_w2_guh",
	"TabPanel": "TabPanel--JGyWlGRG",
	"AddBoard": "AddBoard--Q3bfKvEH",
	"AddIcon": "AddIcon--AIYoEjjg"
};
export default ___CSS_LOADER_EXPORT___;
