// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableHeader--eqa3H4CY{border-bottom:4px solid var(--tavla-checkbox-color);color:var(--tavla-label-font-color);font-size:1.5rem}.HeaderDestination--e4xdxVW7{padding-left:1rem}.HeaderDepartureTime--bLSV3X0t{text-align:right}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Responsive/ResponsiveDepartureTile/ResponsiveTable/ResponsiveTableHeader/ResponsiveTableHeader.module.scss"],"names":[],"mappings":"AAAA,uBACI,mDAAA,CACA,mCAAA,CACA,gBAAA,CAGJ,6BACI,iBAAA,CAGJ,+BACI,gBAAA","sourcesContent":[".TableHeader {\n    border-bottom: 4px solid var(--tavla-checkbox-color);\n    color: var(--tavla-label-font-color);\n    font-size: 1.5rem;\n}\n\n.HeaderDestination {\n    padding-left: 1rem;\n}\n\n.HeaderDepartureTime {\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableHeader": "TableHeader--eqa3H4CY",
	"HeaderDestination": "HeaderDestination--e4xdxVW7",
	"HeaderDepartureTime": "HeaderDepartureTime--bLSV3X0t"
};
export default ___CSS_LOADER_EXPORT___;
