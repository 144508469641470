// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TileHeader--QKlV_ao7{display:flex;justify-content:left;align-items:center;gap:1rem;margin-bottom:1rem}.Heading--UjHLYPQL{margin:0;color:var(--tavla-font-color)}.Icons--TuniTpZt{font-size:3rem;display:flex;align-items:center;gap:.5rem}", "",{"version":3,"sources":["webpack://./src/components/TileHeader/TileHeader.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,oBAAA,CACA,kBAAA,CACA,QAAA,CACA,kBAAA,CAGJ,mBACI,QAAA,CACA,6BAAA,CAGJ,iBACI,cAAA,CACA,YAAA,CACA,kBAAA,CACA,SAAA","sourcesContent":[".TileHeader {\n    display: flex;\n    justify-content: left;\n    align-items: center;\n    gap: 1rem;\n    margin-bottom: 1rem;\n}\n\n.Heading {\n    margin: 0;\n    color: var(--tavla-font-color);\n}\n\n.Icons {\n    font-size: 3rem;\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TileHeader": "TileHeader--QKlV_ao7",
	"Heading": "Heading--UjHLYPQL",
	"Icons": "Icons--TuniTpZt"
};
export default ___CSS_LOADER_EXPORT___;
