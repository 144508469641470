// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button--KuXu8Pax{background-color:inherit;border-radius:5px;color:#fff;padding:8px;cursor:grab;display:flex;width:fit-content;height:fit-content;touch-action:none;border:none}.button--KuXu8Pax:hover{background-color:#fff;color:#000}.flexBetween--P17PcplP{display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/routes/Admin/LiteSettings/styles/global.module.css"],"names":[],"mappings":"AAAA,kBACI,wBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,iBAAA,CACA,WAAA,CAGJ,wBACI,qBAAA,CACA,UAAA,CAGJ,uBACI,YAAA,CACA,6BAAA","sourcesContent":[".button {\n    background-color: inherit;\n    border-radius: 5px;\n    color: white;\n    padding: 8px;\n    cursor: grab;\n    display: flex;\n    width: fit-content;\n    height: fit-content;\n    touch-action: none;\n    border: none;\n}\n\n.button:hover {\n    background-color: white;\n    color: black;\n}\n\n.flexBetween {\n    display: flex;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button--KuXu8Pax",
	"flexBetween": "flexBetween--P17PcplP"
};
export default ___CSS_LOADER_EXPORT___;
