// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PermanentLinePanel--XRmOT9t8{border-bottom:.125rem solid var(--tavla-border-color)}.PermanentLinePanel--XRmOT9t8 .eds-expandable-panel__trigger{padding-left:0;margin-top:.5rem}.Title--oUwsjr9U{width:100%;display:flex;align-items:center}.Name--KHnz1Kqi{margin-right:1rem}.Icon--BISgYu9V{margin-bottom:-0.1rem;margin-right:.5rem}.Lines--ZjdqlL2e{width:100%;height:100%;display:flex;flex-wrap:wrap;margin-top:-1rem}.FilterChip--Gpav3uZC{margin-right:1rem;margin-top:1rem}.TransportIcon--G9EUVwFM{transform:scale(1.5) translateY(-0.05rem);display:flex;justify-content:center;margin-left:.75rem}", "",{"version":3,"sources":["webpack://./src/tiles/admin/RealtimeTile/components/RealtimeDetailPanel/PermanentLinePanel/PermanentLinePanel.module.scss"],"names":[],"mappings":"AAAA,8BACI,qDAAA,CAEA,6DACI,cAAA,CACA,gBAAA,CAIR,iBACI,UAAA,CACA,YAAA,CACA,kBAAA,CAGJ,gBACI,iBAAA,CAGJ,gBACI,qBAAA,CACA,kBAAA,CAGJ,iBACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CAGJ,sBACI,iBAAA,CACA,eAAA,CAGJ,yBACI,yCAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".PermanentLinePanel {\n    border-bottom: 0.125rem solid var(--tavla-border-color);\n\n    & :global .eds-expandable-panel__trigger {\n        padding-left: 0;\n        margin-top: 0.5rem;\n    }\n}\n\n.Title {\n    width: 100%;\n    display: flex;\n    align-items: center;\n}\n\n.Name {\n    margin-right: 1rem;\n}\n\n.Icon {\n    margin-bottom: -0.1rem;\n    margin-right: 0.5rem;\n}\n\n.Lines {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: -1rem;\n}\n\n.FilterChip {\n    margin-right: 1rem;\n    margin-top: 1rem;\n}\n\n.TransportIcon {\n    transform: scale(1.5) translateY(-0.05rem);\n    display: flex;\n    justify-content: center;\n    margin-left: 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PermanentLinePanel": "PermanentLinePanel--XRmOT9t8",
	"Title": "Title--oUwsjr9U",
	"Name": "Name--KHnz1Kqi",
	"Icon": "Icon--BISgYu9V",
	"Lines": "Lines--ZjdqlL2e",
	"FilterChip": "FilterChip--Gpav3uZC",
	"TransportIcon": "TransportIcon--G9EUVwFM"
};
export default ___CSS_LOADER_EXPORT___;
