// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardWrapper--XCPh4_Hs{background-color:var(--tavla-background-color);color:var(--tavla-font-color);padding:0 4rem 2rem 4rem}.Byline--Ulryzvgl{margin-top:2rem;display:flex;align-items:center;color:var(--tavla-font-color)}.Byline--Ulryzvgl img{padding-left:.75rem}.Rotated--MIkxX55A{transform:rotate(-90deg) translate(-100vh);transform-origin:top left;width:100vh;height:100vw}", "",{"version":3,"sources":["webpack://./src/scenarios/DashboardWrapper/DashboardWrapper.module.scss"],"names":[],"mappings":"AAAA,4BACI,8CAAA,CACA,6BAAA,CACA,wBAAA,CAGJ,kBACI,eAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,sBACI,mBAAA,CAIR,mBACI,0CAAA,CACA,yBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".DashboardWrapper {\n    background-color: var(--tavla-background-color);\n    color: var(--tavla-font-color);\n    padding: 0 4rem 2rem 4rem;\n}\n\n.Byline {\n    margin-top: 2rem;\n    display: flex;\n    align-items: center;\n    color: var(--tavla-font-color);\n\n    img {\n        padding-left: 0.75rem;\n    }\n}\n\n.Rotated {\n    transform: rotate(-90deg) translate(-100vh);\n    transform-origin: top left;\n    width: 100vh;\n    height: 100vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DashboardWrapper": "DashboardWrapper--XCPh4_Hs",
	"Byline": "Byline--Ulryzvgl",
	"Rotated": "Rotated--MIkxX55A"
};
export default ___CSS_LOADER_EXPORT___;
