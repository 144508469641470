// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tile--hFoo96Ve{padding:2rem;gap:1rem;overflow:hidden;height:100%;display:flex;flex-direction:column;align-items:center;color:var(--tavla-background-color)}.Title--WcDyyeVt{text-align:center;font-size:1.6rem;margin:0}.Wrapper--yt7U_uKj{padding:1rem;width:fit-content;background-color:#fff;border-radius:1.5rem;line-height:0}", "",{"version":3,"sources":["webpack://./src/components/QRWrapper/QRWrapper.module.scss"],"names":[],"mappings":"AAAA,gBACI,YAAA,CACA,QAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,mCAAA,CAGJ,iBACI,iBAAA,CACA,gBAAA,CACA,QAAA,CAGJ,mBACI,YAAA,CACA,iBAAA,CACA,qBAAA,CACA,oBAAA,CACA,aAAA","sourcesContent":[".Tile {\n    padding: 2rem;\n    gap: 1rem;\n    overflow: hidden;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: var(--tavla-background-color);\n}\n\n.Title {\n    text-align: center;\n    font-size: 1.6rem;\n    margin: 0;\n}\n\n.Wrapper {\n    padding: 1rem;\n    width: fit-content;\n    background-color: white;\n    border-radius: 1.5rem;\n    line-height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tile": "Tile--hFoo96Ve",
	"Title": "Title--WcDyyeVt",
	"Wrapper": "Wrapper--yt7U_uKj"
};
export default ___CSS_LOADER_EXPORT___;
