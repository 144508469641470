// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CloseButton--Oy34fhQZ{align-self:flex-end;cursor:pointer;display:flex;justify-content:flex-end;padding:1rem;background:none;border:0;color:inherit;font-family:inherit;font-size:inherit;font-weight:inherit;margin-left:auto}.Icon--OKo6Ya5z{margin-right:.5rem}", "",{"version":3,"sources":["webpack://./src/components/CloseButton/CloseButton.module.scss"],"names":[],"mappings":"AAAA,uBACI,mBAAA,CACA,cAAA,CACA,YAAA,CACA,wBAAA,CACA,YAAA,CACA,eAAA,CACA,QAAA,CACA,aAAA,CACA,mBAAA,CACA,iBAAA,CACA,mBAAA,CACA,gBAAA,CAGJ,gBACI,kBAAA","sourcesContent":[".CloseButton {\n    align-self: flex-end;\n    cursor: pointer;\n    display: flex;\n    justify-content: flex-end;\n    padding: 1rem;\n    background: none;\n    border: 0;\n    color: inherit;\n    font-family: inherit;\n    font-size: inherit;\n    font-weight: inherit;\n    margin-left: auto;\n}\n\n.Icon {\n    margin-right: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CloseButton": "CloseButton--Oy34fhQZ",
	"Icon": "Icon--OKo6Ya5z"
};
export default ___CSS_LOADER_EXPORT___;
