// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewDayTableRow--cUMWrhcu{border-bottom:none;text-transform:capitalize;width:max-content;white-space:nowrap;border-color:var(--tavla-border-color) !important}", "",{"version":3,"sources":["webpack://./src/components/NewDayTableRow/NewDayTableRow.module.scss"],"names":[],"mappings":"AAAA,0BACI,kBAAA,CACA,yBAAA,CACA,iBAAA,CACA,kBAAA,CAGA,iDAAA","sourcesContent":[".NewDayTableRow {\n    border-bottom: none;\n    text-transform: capitalize;\n    width: max-content;\n    white-space: nowrap;\n\n    // Important used to overwrite Entur design system\n    border-color: var(--tavla-border-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NewDayTableRow": "NewDayTableRow--cUMWrhcu"
};
export default ___CSS_LOADER_EXPORT___;
