// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PulsatingDot--qYRsyAT_{height:50px;width:50px}.OuterCircle--OJ4DRoHg{height:100%;width:100%;border-radius:100%;animation:pulsate--yn7pcES4 1s ease-out;animation-iteration-count:infinite;background-color:var(--tavla-success-font-color)}.InnerCircle--mHIwBMEF{background-color:var(--tavla-success-font-color, 0.3);position:relative;border-radius:100%;top:-75%;left:25%;width:50%;height:50%}@keyframes pulsate--yn7pcES4{0%{opacity:0}50%{opacity:.5}100%{opacity:0}}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Poster/components/PulsatingDot/PulsatingDot.module.scss"],"names":[],"mappings":"AAAA,wBACI,WAAA,CACA,UAAA,CAGJ,uBACI,WAAA,CACA,UAAA,CACA,kBAAA,CACA,uCAAA,CACA,kCAAA,CACA,gDAAA,CAGJ,uBACI,qDAAA,CACA,iBAAA,CACA,kBAAA,CACA,QAAA,CACA,QAAA,CACA,SAAA,CACA,UAAA,CAGJ,6BACI,GACI,SAAA,CAGJ,IACI,UAAA,CAGJ,KACI,SAAA,CAAA","sourcesContent":[".PulsatingDot {\n    height: 50px;\n    width: 50px;\n}\n\n.OuterCircle {\n    height: 100%;\n    width: 100%;\n    border-radius: 100%;\n    animation: pulsate 1s ease-out;\n    animation-iteration-count: infinite;\n    background-color: var(--tavla-success-font-color);\n}\n\n.InnerCircle {\n    background-color: var(--tavla-success-font-color, 0.3);\n    position: relative;\n    border-radius: 100%;\n    top: -75%;\n    left: 25%;\n    width: 50%;\n    height: 50%;\n}\n\n@keyframes pulsate {\n    0% {\n        opacity: 0;\n    }\n\n    50% {\n        opacity: 0.5;\n    }\n\n    100% {\n        opacity: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PulsatingDot": "PulsatingDot--qYRsyAT_",
	"OuterCircle": "OuterCircle--OJ4DRoHg",
	"pulsate": "pulsate--yn7pcES4",
	"InnerCircle": "InnerCircle--mHIwBMEF"
};
export default ___CSS_LOADER_EXPORT___;
