// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal--unYuqdeO{padding:.8rem 1.5rem 1.5rem;text-align:center;height:fit-content}.GridContainer--WWTnHuCC{padding:10%}.Paragraph--stPScuMa{padding:0 1rem;margin:0}.Image--ehQ_jgVR{width:45%;margin:0 auto}.ModalHeader--kjaF0gDt{display:flex;align-items:center}.BottomLink--h7PlH8eS{cursor:pointer;display:inline-block;margin-bottom:3rem}.ModalSubmit--G7YgJMzY{margin-top:1rem}.BackButton--WvvA9xk5{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/scenarios/Modals/Modals.module.scss"],"names":[],"mappings":"AAIA,iBACI,2BAAA,CACA,iBAAA,CACA,kBAAA,CAGJ,yBACI,WAAA,CAGJ,qBACI,cAAA,CACA,QAAA,CAGJ,iBACI,SAAA,CACA,aAAA,CAGJ,uBACI,YAAA,CACA,kBAAA,CAGJ,sBACI,cAAA,CACA,oBAAA,CACA,kBAAA,CAGJ,uBACI,eAAA,CAGJ,sBACI,cAAA","sourcesContent":["@use 'assets/styles/Variables.scss';\n\n// Common styling for modals\n\n.Modal {\n    padding: 0.8rem 1.5rem 1.5rem;\n    text-align: center;\n    height: fit-content;\n}\n\n.GridContainer {\n    padding: 10%;\n}\n\n.Paragraph {\n    padding: 0 1rem;\n    margin: 0;\n}\n\n.Image {\n    width: 45%;\n    margin: 0 auto;\n}\n\n.ModalHeader {\n    display: flex;\n    align-items: center;\n}\n\n.BottomLink {\n    cursor: pointer;\n    display: inline-block;\n    margin-bottom: 3rem;\n}\n\n.ModalSubmit {\n    margin-top: 1rem;\n}\n\n.BackButton {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Modal": "Modal--unYuqdeO",
	"GridContainer": "GridContainer--WWTnHuCC",
	"Paragraph": "Paragraph--stPScuMa",
	"Image": "Image--ehQ_jgVR",
	"ModalHeader": "ModalHeader--kjaF0gDt",
	"BottomLink": "BottomLink--h7PlH8eS",
	"ModalSubmit": "ModalSubmit--G7YgJMzY",
	"BackButton": "BackButton--WvvA9xk5"
};
export default ___CSS_LOADER_EXPORT___;
