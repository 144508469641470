// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer--LjZBOLYO{background-color:var(--tavla-box-background-color);display:flex;justify-content:space-between;padding:4rem 6rem 0 6rem}.FooterHeading--Nyc9K1gc{font-size:2.75rem;margin-top:1.5rem;margin-bottom:2.5rem;font-weight:600}.FooterDescription--RjqcYY74{font-size:2rem;line-height:3rem}.AppImage--S1dwV_zp{height:17rem}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Poster/components/PosterFooter/PosterFooter.module.scss"],"names":[],"mappings":"AAAA,kBACI,kDAAA,CACA,YAAA,CACA,6BAAA,CACA,wBAAA,CAGJ,yBACI,iBAAA,CACA,iBAAA,CACA,oBAAA,CACA,eAAA,CAGJ,6BACI,cAAA,CACA,gBAAA,CAGJ,oBACI,YAAA","sourcesContent":[".Footer {\n    background-color: var(--tavla-box-background-color);\n    display: flex;\n    justify-content: space-between;\n    padding: 4rem 6rem 0 6rem;\n}\n\n.FooterHeading {\n    font-size: 2.75rem;\n    margin-top: 1.5rem;\n    margin-bottom: 2.5rem;\n    font-weight: 600;\n}\n\n.FooterDescription {\n    font-size: 2rem;\n    line-height: 3rem;\n}\n\n.AppImage {\n    height: 17rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": "Footer--LjZBOLYO",
	"FooterHeading": "FooterHeading--Nyc9K1gc",
	"FooterDescription": "FooterDescription--RjqcYY74",
	"AppImage": "AppImage--S1dwV_zp"
};
export default ___CSS_LOADER_EXPORT___;
