// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Background--H9zIjdaW{fill:var(--tavla-background-color)}.Checkmark--zrdEifj1{fill:var(--tavla-success-font-color)}", "",{"version":3,"sources":["webpack://./src/assets/icons/ValidationCheck/ValidationCheck.module.scss"],"names":[],"mappings":"AAAA,sBACI,kCAAA,CAGJ,qBACI,oCAAA","sourcesContent":[".Background {\n    fill: var(--tavla-background-color);\n}\n\n.Checkmark {\n    fill: var(--tavla-success-font-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Background": "Background--H9zIjdaW",
	"Checkmark": "Checkmark--zrdEifj1"
};
export default ___CSS_LOADER_EXPORT___;
