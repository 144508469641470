// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SizePicker--Ok4HfyhM{color:var(--tavla-label-font-color);display:inline-flex;vertical-align:top;gap:1rem}.Radio--inNhzr0L{color:var(--tavla-font-color)}", "",{"version":3,"sources":["webpack://./src/routes/Admin/LogoTab/SizePicker/SizePicker.module.scss"],"names":[],"mappings":"AAAA,sBACI,mCAAA,CACA,mBAAA,CACA,kBAAA,CACA,QAAA,CAGJ,iBACI,6BAAA","sourcesContent":[".SizePicker {\n    color: var(--tavla-label-font-color);\n    display: inline-flex;\n    vertical-align: top;\n    gap: 1rem;\n}\n\n.Radio {\n    color: var(--tavla-font-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SizePicker": "SizePicker--Ok4HfyhM",
	"Radio": "Radio--inNhzr0L"
};
export default ___CSS_LOADER_EXPORT___;
