// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LastUpdated--HS5qBsvd{display:flex;align-items:center}.Heading--PMLH44ze{font-size:2.5rem;line-height:1;padding-left:1.5rem;margin:auto auto auto 0}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Poster/components/LastUpdated/LastUpdated.module.scss"],"names":[],"mappings":"AAAA,uBACI,YAAA,CACA,kBAAA,CAGJ,mBACI,gBAAA,CACA,aAAA,CACA,mBAAA,CACA,uBAAA","sourcesContent":[".LastUpdated {\n    display: flex;\n    align-items: center;\n}\n\n.Heading {\n    font-size: 2.5rem;\n    line-height: 1;\n    padding-left: 1.5rem;\n    margin: auto auto auto 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LastUpdated": "LastUpdated--HS5qBsvd",
	"Heading": "Heading--PMLH44ze"
};
export default ___CSS_LOADER_EXPORT___;
