// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToggleDetailsPanel--P59xa1iT .eds-chip{background-color:var(--tavla-border-color)}.ToggleDetailsPanel--P59xa1iT .eds-filter-chip__icon{background-color:var(--tavla-background-color)}.Container--Auk2AGvm{width:100%;height:100%}.Buttons--nGJPffKq{display:inline-block;padding-right:.5rem;margin-top:.8rem}.FilterChip--nA97_lvm:not(:first-child){margin-top:.8rem;padding-left:.8rem}", "",{"version":3,"sources":["webpack://./src/tiles/admin/StopPlaceTile/components/ToggleDetailsPanel/ToggleDetailsPanel.module.scss"],"names":[],"mappings":"AAEQ,wCACI,0CAAA,CAGJ,qDACI,8CAAA,CAKZ,qBACI,UAAA,CACA,WAAA,CAGJ,mBACI,oBAAA,CACA,mBAAA,CACA,gBAAA,CAGJ,wCACI,gBAAA,CACA,kBAAA","sourcesContent":[".ToggleDetailsPanel {\n    & :global {\n        .eds-chip {\n            background-color: var(--tavla-border-color);\n        }\n\n        .eds-filter-chip__icon {\n            background-color: var(--tavla-background-color);\n        }\n    }\n}\n\n.Container {\n    width: 100%;\n    height: 100%;\n}\n\n.Buttons {\n    display: inline-block;\n    padding-right: 0.5rem;\n    margin-top: 0.8rem;\n}\n\n.FilterChip:not(:first-child) {\n    margin-top: 0.8rem;\n    padding-left: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToggleDetailsPanel": "ToggleDetailsPanel--P59xa1iT",
	"Container": "Container--Auk2AGvm",
	"Buttons": "Buttons--nGJPffKq",
	"FilterChip": "FilterChip--nA97_lvm"
};
export default ___CSS_LOADER_EXPORT___;
