// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimelineWalkMarker--sT1YscRc{display:flex;flex-direction:column;align-items:center;justify-content:center;position:absolute;top:5rem;bottom:0;z-index:1}.Icon--wMcDDvz5{color:var(--tavla-label-font-color);font-size:1.5rem}.Line--X0hYuWAo{border-right:.25rem dotted var(--tavla-label-font-color);width:0;flex:1;margin-top:.25rem;background-color:rgba(0,0,0,0)}", "",{"version":3,"sources":["webpack://./src/routes/Dashboard/Timeline/TimelineWalkMarker/TimelineWalkMarker.module.scss"],"names":[],"mappings":"AAAA,8BACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,QAAA,CACA,QAAA,CACA,SAAA,CAGJ,gBACI,mCAAA,CACA,gBAAA,CAGJ,gBACI,wDAAA,CACA,OAAA,CACA,MAAA,CACA,iBAAA,CACA,8BAAA","sourcesContent":[".TimelineWalkMarker {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    top: 5rem;\n    bottom: 0;\n    z-index: 1;\n}\n\n.Icon {\n    color: var(--tavla-label-font-color);\n    font-size: 1.5rem;\n}\n\n.Line {\n    border-right: 0.25rem dotted var(--tavla-label-font-color);\n    width: 0;\n    flex: 1;\n    margin-top: 0.25rem;\n    background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TimelineWalkMarker": "TimelineWalkMarker--sT1YscRc",
	"Icon": "Icon--wMcDDvz5",
	"Line": "Line--X0hYuWAo"
};
export default ___CSS_LOADER_EXPORT___;
