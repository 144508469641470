// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Switch--P_sVcqwR{width:15rem}.Header--A11xMyHm{display:flex;justify-content:space-between;height:fit-content;padding:2rem 0}.Wrapper--ncsfseWp{display:flex;flex-direction:row;align-items:center;gap:1rem;font-size:medium}.NewBoardWrapper--C2BTll4M{display:flex;flex-direction:row;align-items:center;gap:1rem;font-size:medium;background-color:var(--tavla-background-color);position:relative;width:100%;height:100%;padding:0 1.5rem;border-radius:.25rem;color:var(--tavla-font-color)}.NewBoardWrapper--C2BTll4M:hover{background-color:var(--tavla-selected-color);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/routes/MyBoards/OwnedBoards/OwnedBoards.module.scss"],"names":[],"mappings":"AAAA,kBACI,WAAA,CAGJ,kBACI,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,cAAA,CAGJ,mBACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CACA,gBAAA,CAGJ,2BACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CACA,gBAAA,CACA,8CAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,oBAAA,CACA,6BAAA,CAEA,iCACI,4CAAA,CACA,cAAA","sourcesContent":[".Switch {\n    width: 15rem;\n}\n\n.Header {\n    display: flex;\n    justify-content: space-between;\n    height: fit-content;\n    padding: 2rem 0;\n}\n\n.Wrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 1rem;\n    font-size: medium;\n}\n\n.NewBoardWrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 1rem;\n    font-size: medium;\n    background-color: var(--tavla-background-color);\n    position: relative;\n    width: 100%;\n    height: 100%;\n    padding: 0 1.5rem;\n    border-radius: 0.25rem;\n    color: var(--tavla-font-color);\n\n    &:hover {\n        background-color: var(--tavla-selected-color);\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Switch": "Switch--P_sVcqwR",
	"Header": "Header--A11xMyHm",
	"Wrapper": "Wrapper--ncsfseWp",
	"NewBoardWrapper": "NewBoardWrapper--C2BTll4M"
};
export default ___CSS_LOADER_EXPORT___;
