// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Admin--XiWobtTi{background-color:var(--tavla-background-color);margin-left:auto;margin-right:auto;justify-content:center;padding:2rem 4rem 6rem;color:var(--tavla-font-color)}.Tab--AtgSkCc0{width:12rem;min-height:3rem;height:auto;flex:1}@media(max-width: 50rem){.Admin--XiWobtTi{padding:1rem}.TabList--dFhXUXZH{mask-image:linear-gradient(to right, rgb(0, 0, 0) 85%, rgba(0, 0, 0, 0) 100%)}}", "",{"version":3,"sources":["webpack://./src/routes/Admin/AdminPage.module.scss"],"names":[],"mappings":"AAEA,iBACI,8CAAA,CACA,gBAAA,CACA,iBAAA,CACA,sBAAA,CACA,sBAAA,CACA,6BAAA,CAGJ,eACI,WAAA,CACA,eAAA,CACA,WAAA,CACA,MAAA,CAGJ,yBACI,iBACI,YAAA,CAGJ,mBACI,6EAAA,CAAA","sourcesContent":["@use 'src/assets/styles/Variables.scss';\n\n.Admin {\n    background-color: var(--tavla-background-color);\n    margin-left: auto;\n    margin-right: auto;\n    justify-content: center;\n    padding: 2rem 4rem 6rem;\n    color: var(--tavla-font-color);\n}\n\n.Tab {\n    width: 12rem;\n    min-height: 3rem;\n    height: auto;\n    flex: 1;\n}\n\n@media (max-width: Variables.$breakpoints-medium) {\n    .Admin {\n        padding: 1rem;\n    }\n\n    .TabList {\n        mask-image: linear-gradient(\n            to right,\n            rgba(0, 0, 0, 1) 85%,\n            rgba(0, 0, 0, 0) 100%\n        );\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Admin": "Admin--XiWobtTi",
	"Tab": "Tab--AtgSkCc0",
	"TabList": "TabList--dFhXUXZH"
};
export default ___CSS_LOADER_EXPORT___;
